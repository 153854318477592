import(/* webpackMode: "eager" */ "/var/www/clients/client1/web1/web/frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/var/www/clients/client1/web1/web/frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/var/www/clients/client1/web1/web/frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/clients/client1/web1/web/frontend/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/clients/client1/web1/web/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/clients/client1/web1/web/frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/clients/client1/web1/web/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/var/www/clients/client1/web1/web/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/var/www/clients/client1/web1/web/frontend/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/clients/client1/web1/web/frontend/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/var/www/clients/client1/web1/web/frontend/public/img/logo.webp");
;
import(/* webpackMode: "eager" */ "/var/www/clients/client1/web1/web/frontend/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/clients/client1/web1/web/frontend/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/clients/client1/web1/web/frontend/src/Components/Navbar/Links.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/clients/client1/web1/web/frontend/src/Components/Navbar/MainMenuMobile.tsx");
