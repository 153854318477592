'use client'
import React, { useState } from 'react'
import { Modal } from 'flowbite-react';
import { FaEnvelope } from 'react-icons/fa6';
import Image from 'next/image';
import imgNewsLetter from '../../../public/img/newsletter-vae.webp';
import { useRouter } from 'next/navigation';

type Props = {
    position?: 'sidebar' | 'footer'
    isNewsLetter?: boolean
}

const NewsletterModal = ({ position, isNewsLetter }: Props) => {
    
    const router = useRouter();
    
    const [showModal, setShowModal] = useState(isNewsLetter)

    const handleCloseModal = () => {
        if (isNewsLetter) {
            router.replace('/biens-en-vente')
        }
        setShowModal(false)
    }

    return (
        <>
            {position === 'footer' &&
                <div className="text-center">
                    <button 
                        onClick={() => setShowModal(true)} 
                        className='text-white flex items-center md:text-2xl bg-red-700 hover:bg-red-800 p-2 rounded-md'
                    >
                       <span className="bg-red-900 p-1 rounded-md">
                           <FaEnvelope className='' />
                        </span>
                        <span className='ms-2 items-center'>
                           Newsletter
                        </span>
                    </button>
                </div>
            }
            {position === 'sidebar' &&
                <div className="">
                    <button 
                        onClick={() => setShowModal(true)} 
                        className="btn btn-primary btn-circle btn-lg shadow-lg"
                    >
                        <Image
                            src={imgNewsLetter}
                            alt="Newsletter"
                        />
                    </button>
                </div>
            }

            {showModal &&
                <Modal show={showModal} onClose={() => handleCloseModal()} size={'2xl'} position={"center"} className='z-[1000] [&>div]:flex [&>div]:items-start [&>div]:max-h-[90dvh] [&>div]:w-full [&>div]:h-full [&>div>div]:max-h-none [&>div>div]:h-full  [&>div>div]:w-full'>
                    <Modal.Header className=''>
                        <div className="text-base md:text-xl font-bold">Inscription à la newsletter</div>
                    </Modal.Header>
                    <Modal.Body className='overflow-hidden p-0'>
                        <iframe
                            width={"100%"}
                            height={"100%"}
                            src="https://a68028c3.sibforms.com/serve/MUIFANxVBeP3J-BSPFNTeEqo-kqJvS0LkK77RRtMCBv-botafu2cVapN3pnK7nuplql0ja4j91Ys9L2FMUzKA0aLUHfx_NCgzrguHjWePyyJTdxSU_QKHaw5V1A3YPt8Jf0wE3pqvCUkeidW3T60Ag8QU6MonhfzCB-PH-nk7u5gAr-kFw0MyUTo-KzhIqhs-RXBc9ra6mtl_gFv"
                            allowFullScreen
                        >
                        </iframe>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}

export default NewsletterModal